module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://beadswap.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"F:\\KimchiSwap\\Final Kwikswap 25-12-2020\\Main Repos\\Beadswap\\beadswapfrontend\\src\\layouts\\index.js","docs":"F:\\KimchiSwap\\Final Kwikswap 25-12-2020\\Main Repos\\Beadswap\\beadswapfrontend\\src\\layouts\\docs.js","blog":"F:\\KimchiSwap\\Final Kwikswap 25-12-2020\\Main Repos\\Beadswap\\beadswapfrontend\\src\\layouts\\blogPost.js"},"remarkPlugins":[null],"rehypePlugins":[null],"gatsbyRemarkPlugins":["gatsby-remark-embedder","gatsby-remark-copy-linked-files","gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-twitter-cards","options":{"title":"Beadswap","separator":"|","author":"@BeadswapProtocol","background":"F:\\KimchiSwap\\Final Kwikswap 25-12-2020\\Main Repos\\Beadswap\\beadswapfrontend\\static\\images\\twitter_card_bg.jpg","fontColor":"#FF3093","fontStyle":"sans-serif","titleFontSize":124,"fontFile":"F:\\KimchiSwap\\Final Kwikswap 25-12-2020\\Main Repos\\Beadswap\\beadswapfrontend\\static\\fonts\\Inferi-Normal.ttf"}},"gatsby-remark-smartypants",{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"showCaptions":true}}],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"F:\\KimchiSwap\\Final Kwikswap 25-12-2020\\Main Repos\\Beadswap\\beadswapfrontend"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a5daf19435e33de939cd086537aa7f7c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
